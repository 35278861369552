import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { getUser } from "../../context/auth/auth-functions"
import AuthContext from "../../context/auth/auth-context"
import CartContext from "../../context/cart/cart-context"
import CheckoutContext from "../../context/checkout/checkout-context"

import CheckoutWrapper, {
  CheckoutOptionsStyles,
  CheckoutSplitStyling,
  RightSideSection,
} from "../../components/checkout/checkout-wrapper"

import OrderSummary from "../../components/checkout/order-summary"
import PaymentFrame from "../../components/checkout/payment-frame"
import ContinueButton from "../../components/checkout/continue-button"

import payfastLogo from "../../images/payfast-logo.png"
import visaLogo from "../../images/visa-logo.png"
import instantEftLogo from "../../images/instant-eft-logo.png"
import masterpassLogo from "../../images/masterpass-logo.png"
import zapperLogo from "../../images/zapper-logo.png"
import useOrderFetcher from "../../hooks/checkout-order-fetcher"
import { createPayment } from "../../api/payment"
import CheckSquare from "../../images/icons/check-square"
import {
  analyticsAddPaymentInfo,
  analyticsPurchase,
} from "../../services/google-analytics"
import { pixelPurchase } from "../../services/facebook-pixel"
import LinkWrapper from "../../components/reusable-components/link-wrapper"

const Payment = ({ location }) => {
  const { performLogout } = useContext(AuthContext)
  const { getCart, cartMeta, cartShipping, cartContents } =
    useContext(CartContext)
  const {
    paymentOptions,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    selectedBranch,
    availableBranches,
  } = useContext(CheckoutContext)
  const [loading, setLoading] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [apiError, setApiError] = useState("")

  const quoteRequestOption = paymentOptions?.find(option => option.paymentCode === "quoterequest");
  // If over distance and quote request option exists, select it
  if (cartShipping?.meta_data?.over_distance === "true" && quoteRequestOption) {
    setSelectedPaymentMethod(quoteRequestOption);
  }

  // This is a very special useState! It fetches the order for us if it is present in the URL
  const [orderData, setOrderData] = useOrderFetcher({
    setApiError,
  })

  // PaymentRequest is done, let's capture the order data or redirect if necessary
  const paymentMethodDone = (newOrder) => {
    if (newOrder && newOrder.id) {
      analyticsPurchase(newOrder)
      analyticsAddPaymentInfo(cartContents)
      pixelPurchase(newOrder)
    }

    if (selectedPaymentMethod?.paymentCode == "manualEft") {
      if (newOrder && newOrder.id) {
        navigate(`/checkout/summary/?orderid=${newOrder.id}`, { replace: true })
      }
    } else if (selectedPaymentMethod?.paymentCode == "quoterequest") {
      if (newOrder && newOrder.id) {
        navigate(`/checkout/summary/?orderid=${newOrder.id}`, { replace: true })
      }
    }
    // It's very useful to save the new order ID into the URL
    if (newOrder && newOrder.id) {
      let newPath = `/checkout/payment/?orderid=${newOrder.id}`
      history.pushState(null, "", newPath)
    }
  }

  // Place order and get payment method data
  const placeOrder = async () => {
    setLoading(true)
    const { token } = getUser()
    let paymentObject = {
      host_url: location.origin,
      selected_payment_method: selectedPaymentMethod?.paymentCode,
      selected_collection_branch: availableBranches[selectedBranch],
    }

    // Build data object for the API request. If order data available, send it
    // otherwise send cart data to place a new order
    if (orderData && orderData.id) {
      paymentObject = {
        ...paymentObject,
        ...orderData,
      }
    } else {
      paymentObject = {
        ...paymentObject,
        ...cartMeta,
      }
    }

    // Let's pay for order/place order and get payment method data
    try {
      const paymentResponse = await createPayment(
        paymentObject,
        token,
        performLogout
      )

      // Refresh the now empty cart
      getCart()

      // Payment response valid. Save our new data to states
      if (paymentResponse && paymentResponse.order) {
        setPaymentData(paymentResponse)
        setOrderData(paymentResponse.order)
        setApiError("")
        paymentMethodDone(paymentResponse.order)
      } else {
        throw "No order data in API response"
      }
    } catch (error) {
      console.log("PaymentRequest error = ", error)
      if (
        error &&
        error.message &&
        orderData &&
        orderData.id &&
        error.message.includes("already been paid")
      ) {
        // Order is already paid! Redirect to order summary page
        setApiError("")
        navigate(`/checkout/summary/?orderid=${orderData.id}`)
      } else if (error && error.code && error.code.startsWith("bad_cart")) {
        // Cart is bad. Refetch cart, take the user to the home page and open the cart drawer
        setApiError("")
        navigate(`/?cart`)
      } else {
        // catastrophic failure
        setApiError(
          "Oh no! Something went wrong while trying to prepare your payment gateway. We are working on getting it fixed. Please try again or contact us for support."
        )
      }
    }
    setLoading(false)
  }

  // On Continue click, go to payment section
  const continueButtonHandler = () => {
    placeOrder()
  }

  return (
    <CheckoutWrapper
      checkoutStep="payment"
      orderData={orderData}
      loading={loading}
      location={location}
    >
      {cartShipping?.meta_data?.over_distance == "true" && (
        <StyledShippingMessage className="notice red-text">
          {cartShipping.meta_data.message}
        </StyledShippingMessage>
      )}
      {apiError && <p>{apiError}</p>}
      {!paymentData.order ? (
        <>
          <CheckoutSplitStyling>
            <div>
            <CheckoutOptionsStyles>
                {paymentOptions?.map((option, i) => {
                  if (cartShipping?.meta_data?.over_distance !== "true" || option.paymentCode === "quoterequest") {
                    return (
                      <QuoteRequestButton
                        key={i}
                        className={
                          option.paymentCode === selectedPaymentMethod?.paymentCode
                            ? "button-active"
                            : ""
                        }
                        onClick={() => {
                          setSelectedPaymentMethod(option);
                        }}
                      >
                        {option.label}
                        <CheckSquare
                          className={
                            option.paymentCode === selectedPaymentMethod?.paymentCode
                              ? "selected"
                              : ""
                          }
                        ></CheckSquare>
                      </QuoteRequestButton>
                    );
                  }
                  return null;
                })}
              </CheckoutOptionsStyles>
              <div>
                <p>{selectedPaymentMethod?.description}</p>
                <StyledLinkWrapper to="/terms-and-conditions/">
                  <em> *See our terms and conditions</em>
                </StyledLinkWrapper>
              </div>
              {selectedPaymentMethod?.paymentCode === "payfast" ? (
                <LogoRow>
                  <ImageStyling src={payfastLogo} alt="paygateLogos" />
                  <ImageStyling src={visaLogo} alt="paygateLogos" />
                  <ImageStyling src={instantEftLogo} alt="paygateLogos" />
                  <ImageStyling src={masterpassLogo} alt="paygateLogos" />
                  <ImageStyling src={zapperLogo} alt="paygateLogos" />
                </LogoRow>
              ) : null}
            </div>

            <RightSideSection>
              <OrderSummary givenOrder={orderData} showShipping={true} />
              <ContinueButton
                  callback={continueButtonHandler}
                  text={
                    selectedPaymentMethod?.paymentCode === "quoterequest"
                      ? "Request a Quote"
                      : orderData
                      ? "Pay for order"
                      : "Place order"
                  }
                  disabled={loading}
                />
            </RightSideSection>
          </CheckoutSplitStyling>
        </>
      ) : (
        <PaymentFrame
          paymentMethod={selectedPaymentMethod?.paymentCode}
          paymentData={paymentData}
          orderData={orderData}
        />
      )}
    </CheckoutWrapper>
  )
}

export default Payment

// ===============
//     STYLES
// ===============


const LogoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
`

const ImageStyling = styled.img`
  object-fit: contain;
  max-height: 80px;
  max-width: 120px;
  margin: 10px;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    max-height: 40px;
    max-width: 60px;
  }
`
const StyledLinkWrapper = styled(LinkWrapper)`
  font-size: 0.75rem;
`
const StyledShippingMessage = styled.div`
  &.notice {
    max-width: 720px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    box-shadow: 0px 0px 25px ${({ theme }) => theme.colors.lightgrey3};
    padding: 30px 40px;
    margin-top: 20px;
    line-height: 1.5;
  }
    
  &.red-text {
    color: red;
  }
`
const QuoteRequestButton = styled.button`
  width: auto;  
  min-width: 200px;  
  max-width: 300px;  
  padding: 10px 20px; 
  margin: 0 auto;  
  display: block;  
`;
